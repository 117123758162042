import styles from "../styles/user.module.scss";
import { Heading, Text, Box } from "@chakra-ui/react";
import {ReactComponent as LightMode} from '../images/lightMode.svg';

const User = (props) => {
  return (
    <section className={styles.panel}>
      <Box>
        <Heading>user</Heading>
        <Text>{props.address}</Text>
      </Box>
      <LightMode alt="Light Mode" />
    </section>
  );
};

export default User;
