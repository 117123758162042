import styles from "../styles/index.module.scss";
import {useAddress} from "@thirdweb-dev/react";
import {useEffect, useState} from "react";
import {treasury} from "../contractABI/treasury";
import {blackhole} from "../contractABI/blackhole";
import {quickSwap} from "../contractABI/quickSwap";
import {aave} from "../contractABI/aave";
import {getPermittedToken, getTokenMeta, getTokenPrice} from "../modules/tokens";
import {
    getTokenDebtLive,
    getTokenTreasuryBalance,
    getTokenTreasuryWeight,
    getTotalReservesLive
} from "../modules/treasury";
import Sidebar from "./sidebar";
import User from "./user";
import Dashboard from "./dashboard";
import PortfolioPie from "./portfolioPie";
import PortfolioArea from "./portfolioArea";
import Portfolio from "./portfolio";
import {HStack} from "@chakra-ui/react";
import {getDaoSnapshots} from "../modules/utilities";
import NavChart from "./navChart";
import NavPerCoinChart from "./navPerCoinChart";

const Treasury = () => {

    // console.log(process.env.NODE_ENV);
    // console.log(process.env.REACT_APP_ROUNDTABLE_ADDRESS);

    const address = useAddress();
    const [token, setToken] = useState([]);
    const [tokenMeta, setTokenMeta] = useState({});
    const [usedToken, setUsedToken] = useState([]);
    const [usedTokenMeta, setUsedTokenMeta] = useState({});
    const [totalReservesLive, setTotalReservesLive] = useState(0);
    const [snapshots, setSnapshots] = useState({});

    const getTokensMeta = async () => {
        const tokenList = await getPermittedToken(true);
        const metaToken = {};
        for (let i = 0; i < tokenList.length; i++) {
            metaToken[tokenList[i]] = await getFullTokenMeta(tokenList[i]);
        }
        return metaToken;
    };

    const getFullTokenMeta = async (tokenAddress) => {

        const tokenMeta = await getTokenMeta(tokenAddress);
        const tokenPrice = await getTokenPrice(tokenAddress);
        const tokenBalance = await getTokenTreasuryBalance(tokenAddress);
        const tokenWeigth = await getTokenTreasuryWeight(tokenAddress);
        const tokenDebt = await getTokenDebtLive(tokenAddress);

        // console.log(tokenDebt);

        return {
            name: tokenMeta['name'],
            symbol: tokenMeta['symbol'],
            decimals: tokenMeta['decimals'],
            price: tokenPrice,
            treasuryBalance: tokenBalance,
            aaveDebtBalance: tokenDebt,
            tokenWeight: tokenWeigth,
        };
    };

    const setTokens = (r) => {
        setTokenMeta(r);
        setUsedToken(Object.keys(r).filter((k) => r[k].treasuryBalance));
        setUsedTokenMeta(
            Object.keys(r)
                .filter((k) => r[k].treasuryBalance)
                .reduce((a, v) => ({...a, [v]: r[v]}), {})
        );
    };

    useEffect(() => {
        async function getReserve() {
            if (token && tokenMeta) {
                return await getTotalReservesLive();
            }
        }

        getReserve().then(r => {
            setTotalReservesLive(r);
        });
    }, [token, tokenMeta]);

    useEffect(() => {
        getDaoSnapshots().then(r => setSnapshots(r));
        getPermittedToken(true).then((r) => setToken(r));
        getTokensMeta().then((r) => setTokens(r));
    }, []);

    // CATCH EVENTS
    treasury.on("Deposit", (r) => {
        getTokensMeta().then((r) => setTokens(r));
        console.log("CATCH EVENT: Deposit = " + r);
    });

    treasury.on("Withdrawal", (r) => {
        getTokensMeta().then((r) => setTokens(r));
        console.log("CATCH EVENT: Withdrawal = " + r);
    });

    treasury.on("Mantainance", (r) => {
        getTokensMeta().then((r) => setTokens(r));
        console.log("CATCH EVENT: Mantainance");
    });

    blackhole.on("Bonfire", (r) => {
        getTokensMeta().then((r) => setTokens(r));
        console.log("CATCH EVENT: Bonfire");
    });

    quickSwap.on("ExecuteSwap", (r) => {
        getTokensMeta().then((r) => setTokens(r));
        console.log("CATCH EVENT: ExecuteSwap");
    });

    aave.on("Borrow", (r) => {
        getTokensMeta().then((r) => setTokens(r));
        console.log("CATCH EVENT: Borrow");
    });

    aave.on("Repay", (r) => {
        getTokensMeta().then((r) => setTokens(r));
        console.log("CATCH EVENT: Repay");
    });

    return (
        <HStack className={styles.panel}>
            <Sidebar active="dashboard"/>
            <section className={styles.page}>
                <User address={address}/>
                <section className={styles.board}>
                    <Dashboard
                        token={usedToken}
                        tokenMeta={usedTokenMeta}
                        totalReservesLive={totalReservesLive}
                    />
                </section>
                {token.length && tokenMeta !== {} ? (
                    <section className={styles.board}>
                        <NavChart snapshots={snapshots}/>
                        <NavPerCoinChart snapshots={snapshots}/>
                    </section>
                ) : (
                    ""
                )}
                <section className={styles.board}>
                    <Portfolio
                        token={usedToken}
                        tokenMeta={usedTokenMeta}
                        totalReservesLive={totalReservesLive}
                        type="assets"
                    />
                </section>
                <section className={styles.board}>
                    <Portfolio
                        token={usedToken}
                        tokenMeta={usedTokenMeta}
                        totalReservesLive={totalReservesLive}
                        type="liabilities"
                    />
                </section>
            </section>
        </HStack>
    );
};

export default Treasury;
