import {ethers} from "ethers";
import {abi} from "../contractABI/erc20";
import {signer} from "../contractABI/treasury";
import {getTokenMeta, getTokenPrice} from "./tokens";
import {
    getDepositFee,
    getIntrinsicValue,
    getTotalReservesLive,
    getWithdrawalFee
} from "./treasury";
import {contractAddress} from "./contract";
import axios from "axios";

const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";

export function formatDate(data, formato = "it-IT") {
    let options = { year: "2-digit", month: "2-digit", day: "2-digit" };
    let dataformattata = new Date(data);
    return dataformattata.toLocaleDateString(formato, options);
}
const balanceOf = async (tokenAddress, holderAddress) => {
    const storage = JSON.parse(sessionStorage.getItem("balanceOf")) ?? {};
    let holderBalance = {...storage};
    const tokenMeta = await getTokenMeta(tokenAddress);
    if (!holderBalance[holderAddress]) {
        holderBalance[holderAddress] = {};
    }
    try {
        const erc20 = new ethers.Contract(tokenAddress, abi, signer);
        const balance = await erc20.balanceOf(holderAddress);
        holderBalance[holderAddress][tokenAddress] = balance / 10 ** tokenMeta['decimals'];
        sessionStorage.setItem('balanceOf', JSON.stringify(holderBalance));
    } catch (e) {
        console.log(e);
    }

    return holderBalance[holderAddress] ? holderBalance[holderAddress][tokenAddress] : 0;
}

const getAllowance = async (ownerAddress, spenderAddress, tokenAddress) => {
    const storage = JSON.parse(sessionStorage.getItem("allowance")) ?? {};
    let ownerAllowance = {...storage};
    const tokenMeta = await getTokenMeta(tokenAddress);
    if (!ownerAllowance[spenderAddress]) {
        ownerAllowance[spenderAddress] = {};
    }
    try {
        const erc20 = new ethers.Contract(tokenAddress, abi, signer);
        const allowance = await erc20.allowance(ownerAddress, spenderAddress);
        ownerAllowance[spenderAddress][tokenAddress] = allowance / 10 ** tokenMeta['decimals'];
        sessionStorage.setItem('allowance', JSON.stringify(ownerAllowance));
    } catch (e) {
        console.log(e);
    }

    return ownerAllowance[spenderAddress] ? ownerAllowance[spenderAddress][tokenAddress] : 0;
}

const setAllowance = async (tokenAddress, amount) => {
    const spender = await contractAddress('treasury');
    try {
        const tokenMeta = await getTokenMeta(tokenAddress);
        const erc20 = new ethers.Contract(tokenAddress, abi, signer);
        await erc20.approve(spender, amount * (10 ** tokenMeta['decimals']));
    } catch (e) {
        return false;
    }
    return amount;
}

const calculateExchange = async (aTokenAddress, bTokenAddress, amountToExchange, typeExchange = "deposit") => {
    let amountToSend;
    // const aTokenMeta = getTokenMeta(aTokenAddress);
    // const bTokenMeta = getTokenMeta(bTokenAddress);
    // const totalReserves = await getTotalReservesLive();
    let tableIntrinsicValue = await getIntrinsicValue();
    tableIntrinsicValue = tableIntrinsicValue / 10 ** 18;
    const depositFee = await getDepositFee();
    const withdrawalFee = await getWithdrawalFee();
    if (typeExchange === "deposit") {
        let valueAmount = await getTokenPrice(aTokenAddress);
        valueAmount = (valueAmount * amountToExchange);
        const value = valueAmount - (valueAmount * depositFee / 10000);
        amountToSend = value / tableIntrinsicValue;
    } else if (typeExchange === "withdraw") {
        const liquidityIntrinsicValue = await getTokenPrice(aTokenAddress);
        const intrinsicRatio = tableIntrinsicValue / liquidityIntrinsicValue;
        const value = amountToExchange * intrinsicRatio;
        amountToSend = value - (value * withdrawalFee / 10000);
    }
    return amountToSend;
}

const getDaoSnapshots = async () => {
    const res = await axios.get(
        process.env.REACT_APP_ROUNDTABLE_ADDRESS + "snapshots"
    );
    const {data} = await res;
    return data.data.filter(el => el['dao'] === 'eth');
    // setContractAddress(data["address"]);
}

export {ZERO_ADDRESS, balanceOf, getAllowance, setAllowance, calculateExchange, getDaoSnapshots};