import styles from "../styles/login.module.scss";
import {
    ChainId,
    useAddress,
    useMetamask,
    useNetwork,
} from "@thirdweb-dev/react";
import {Center} from "@chakra-ui/react";
import {ReactComponent as CGuyLogo} from '../images/cguys-logo.svg';
import {ReactComponent as RoundLogo} from '../images/round-table-logo-02.svg';

const Login = () => {
    const desiredChain = ChainId.Polygon;
    const address = useAddress();
    const connectWithMetamask = useMetamask();
    const [, switchNetwork] = useNetwork();

    return (
        <section className={styles.page}>
            <div>
                <div>
                    <RoundLogo className={styles.logo} />
                </div>
                {address ? (
                    <Center>
                        <button
                            className={styles.login_button}
                            onClick={() => switchNetwork(desiredChain)}
                        >
                            Switch Network
                        </button>
                    </Center>
                ) : (
                    <Center>
                        <button
                            className={styles.login_button}
                            onClick={() => connectWithMetamask()}
                        >
                            Connect Wallet
                        </button>
                    </Center>
                )}
            </div>
        </section>
    );
};

export default Login;
