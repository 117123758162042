import { ethers } from "ethers";

let provider;
let blackhole;
let signer;

const address = "0x63C878d942d5caF18edEc72695eF0464052f339C";
const abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_authority",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Bonfire",
    type: "event",
  },
  // {
  //   inputs: [
  //     {
  //       internalType: "uint256",
  //       name: "amount",
  //       type: "uint256",
  //     },
  //   ],
  //   name: "bonfire",
  //   outputs: [],
  //   stateMutability: "nonpayable",
  //   type: "function",
  // },
  {
    inputs: [],
    name: "bonfire",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "isArthur",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isLancelot",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isPerceval",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isTristan",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
} else {
  let provider = ethers.getDefaultProvider(
    "https://polygon-mumbai.g.alchemy.com/v2/joZtg6ZYzf-12iYOZey-qGi8CKYcJH5W"
  ); // Mumbai
  let signer = new ethers.VoidSigner(address, provider);
}

blackhole = new ethers.Contract(address, abi, signer);
export { blackhole, provider, signer };
