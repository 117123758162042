import styles from "../styles/portfolioPie.module.scss";
import {
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer, LineChart, Line, Legend,
} from "recharts";
import {Heading, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {formatDate} from "../modules/utilities";

const NavPerCoinChart = ({snapshots}) => {
    const [data, setData] = useState({});

    useEffect(() => {
        if (snapshots.length > 0) {
            const data = snapshots.map(e => {
                return {
                    'NAV per coin': e['nav_per_coin'],
                    'ETH price': e['btc_price'],
                    'snapshot_time': formatDate(e['snapshot_time'])
                }
            });
            setData(data);
        }
    }, [snapshots]);

    return (
        <section className={styles.panel}>
            <VStack className={styles.dashboard}>
                {/*<Heading>NAV Per Coin / BTC Chart</Heading>*/}
                <div style={{width: "100%", height: 500}}>
                    {JSON.stringify(data) !== '{}' ? (
                        <ResponsiveContainer className={styles.chartContainer}>
                            <LineChart
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <XAxis dataKey="snapshot_time" fontSize={12}/>
                                <YAxis type="number" unit="$"  fontSize={13}/>
                                <Tooltip/>
                                <Legend verticalAlign="top"/>
                                <Line type="monotone" dataKey="NAV per coin" stroke="#688446"/>
                                <Line type="monotone" dataKey="ETH price" stroke="#625FFA"/>
                            </LineChart>
                        </ResponsiveContainer>) : ""}
                </div>
            </VStack>
        </section>
    );
};

export default NavPerCoinChart;
