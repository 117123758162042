import styles from "../styles/portfolioPie.module.scss";
import {
    ResponsiveContainer,
    AreaChart,
    XAxis,
    YAxis,
    Tooltip,
    Area, Legend
} from "recharts";
import {Heading, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {formatDate} from "../modules/utilities";

const NavChart = ({snapshots}) => {
    const [data, setData] = useState({});

    useEffect(() => {
        if (snapshots.length > 0) {
            const data = snapshots.map(e => {
                return {'NAV': e['nav'], 'snapshot_time': formatDate(e['snapshot_time'])}
            });
            setData(data);
        }
    }, [snapshots]);

    return (
        <section className={styles.panel}>
            <VStack className={styles.dashboard}>
                {/*<Heading>NAV Chart</Heading>*/}
                <div style={{width: "100%", height: 500}}>
                    {JSON.stringify(data) !== '{}' ? (
                        <ResponsiveContainer className={styles.chartContainer}>
                            <AreaChart
                                data={data}
                                margin={{top: 10, right: 30, left: 0, bottom: 0,}}>
                                <XAxis dataKey="snapshot_time" fontSize={12}/>
                                <YAxis type="number" unit="$"  fontSize={13}/>
                                <Tooltip/>
                                <Legend verticalAlign="top"/>
                                <Area type="monotone" dataKey="NAV" fill="#FFE900" fillOpacity=".9" opacity="1"/>
                            </AreaChart>
                        </ResponsiveContainer>
                    ) : ""}
                </div>
            </VStack>
        </section>
    );
};

export default NavChart;
