import {useAddress, useNetworkMismatch} from "@thirdweb-dev/react";
import Login from "./components/login";
import Treasury from "./components/treasury";
import "./styles/globals.scss";

export default function Home() {
    const address = useAddress();
    const isMismatched = useNetworkMismatch();

    return (
        <div className="App">
            {address && !isMismatched ? <Treasury/> : <Login/>}
        </div>
    );
}
