import styles from "../styles/dashboard.module.scss";
import {HStack, Text, VStack} from "@chakra-ui/react";
import {useEffect, useState} from "react";
import {getTotalLiabilitiesLive} from "../modules/treasury";
import {getTokenTotalSupply} from "../modules/tokens";
import {contractAddress} from "../modules/contract";
import {treasury} from "../contractABI/treasury";

const Dashboard = ({token, tokenMeta, totalReservesLive}) => {
    const [totalLiabilities, setTotalLiabilities] = useState(0);
    const [totalReserves, setTotalReserves] = useState(0);
    const [totalSupply, setTotalSupply] = useState(0);
    const [nav, setNav] = useState(0);
    const [navPerCoin, setNavPerCoin] = useState(0);
    const [leverage, setLeverage] = useState(0);

    const getTotalSupply = async () => {
        return await getTokenTotalSupply(contractAddress('tablecoin'));
    };

    const calculateTotalSupply = () => {
        getTotalSupply().then((r) => setTotalSupply(r / 10 ** 18));
        // getNetAssetValuePerCoin().then((r) => setIntrinsicValue(r / 10 ** 18));
    };

    const caluclateLeverage = () => {
        const weth = "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619";
        if (tokenMeta[weth]) {
            const ethTreasuryBalance = tokenMeta[weth]['treasuryBalance'];
            const ethPrice = tokenMeta[weth]['price'];
            return ethTreasuryBalance * ethPrice / calculateNav();
        }
        return 0;
    }

    const calculateNavCoin = () => {
        if (totalSupply) {
            return (totalReserves - totalLiabilities) / totalSupply;
        }
        return 0;
    }

    const calculateNav = () => {
        return totalReserves - totalLiabilities;
    }

    useEffect(() => {
        setTotalLiabilities(getTotalLiabilitiesLive(tokenMeta));
        calculateTotalSupply();
    }, [tokenMeta]);

    useEffect(() => {
        setTotalReserves(totalReservesLive);
    }, [totalLiabilities, totalReservesLive]);

    useEffect(() => {
        setNav(calculateNav());
        setNavPerCoin(calculateNavCoin());
    }, [totalReserves, totalLiabilities]);

    useEffect(() => {
        setLeverage(caluclateLeverage());
    }, [tokenMeta, totalReserves, totalLiabilities]);

    // CATCH EVENTS
    treasury.on("Deposit", (r) => {
        calculateTotalSupply();
        console.log("CATCH EVENT: Deposit = " + r);
    });

    treasury.on("Withdrawal", (r) => {
        calculateTotalSupply();
        console.log("CATCH EVENT: Withdrawal = " + r);
    });

    return (
        <>
            <section className={styles.panel}>
                <VStack className={styles.dashboard}>
                    <HStack className={styles.card}>
                        <Text className={styles.label}>Total Assets</Text>
                        <Text className={styles.data}>
                            ${totalReserves.toFixed(2)} <small>({totalReserves})</small>
                        </Text>
                    </HStack>
                    <HStack className={styles.card}>
                        <Text className={styles.label}>Total Liabilities</Text>
                        <Text className={styles.data}>
                            ${totalLiabilities.toFixed(2)} <small>({totalLiabilities})</small>
                        </Text>
                    </HStack>
                    <HStack className={styles.card}>
                        <Text className={styles.label}>Net Asset Value</Text>
                        <Text className={styles.data}>
                            ${(nav).toFixed(2)}
                            <small>({nav})</small>
                        </Text>
                    </HStack>
                </VStack>
            </section>

            <section className={styles.panel}>
                <VStack className={styles.dashboard}>
                    <HStack className={styles.card}>
                        <Text className={styles.label}>TableETH total supply</Text>
                        <Text className={styles.data}>
                            {totalSupply.toFixed(2)} <small>({totalSupply})</small>
                        </Text>
                    </HStack>
                    <HStack className={styles.card}>
                        <Text className={styles.label}>Net Assets Value per coin</Text>
                        <Text className={styles.data}>
                            ${navPerCoin.toFixed(2)}
                            <small>({navPerCoin})</small>
                        </Text>
                    </HStack>
                    <HStack className={styles.card}>
                        <Text className={styles.label}>Leverage</Text>
                        <Text className={styles.data}>
                            {(leverage * 100).toFixed(0)}%
                        </Text>
                    </HStack>
                </VStack>
            </section>
        </>
    )
};

export default Dashboard;
