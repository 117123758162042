import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ChainId, ThirdwebProvider} from "@thirdweb-dev/react";
import {ChakraProvider} from "@chakra-ui/react";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import TreasuryInteract from "./pages/treasuryInteract";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <ThirdwebProvider desiredChainId={ChainId.Polygon}>
            <ChakraProvider>
                <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
                    <Routes>
                        <Route path="/" element={<App/>}></Route>
                        <Route path="/treasury/:interact" element={<TreasuryInteract/>}></Route>
                    </Routes>
                </BrowserRouter>
            </ChakraProvider>
        </ThirdwebProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
