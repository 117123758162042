import {ZERO_ADDRESS} from "./utilities";
import {tablecoin} from "../contractABI/tablecoin";
import {treasury} from "../contractABI/treasury";
import {aave} from "../contractABI/aave";
import {authority} from "../contractABI/authority";
import {blackhole} from "../contractABI/blackhole";
import {cguy} from "../contractABI/cguy";
import {erc20Mapping} from "../contractABI/erc20Mapping";
import {priceFeed} from "../contractABI/priceFeed";
import {quickSwap} from "../contractABI/quickSwap";

const contractAddress = async (contractName) => {
    let contract;
    switch (contractName) {
        case 'aave':
            contract = aave;
            break;
        case 'authority':
            contract = authority;
            break;
        case 'blackhole':
            contract = blackhole;
            break;
        case 'cguy':
            contract = cguy;
            break;
        case 'mapping':
            contract = erc20Mapping;
            break;
        case 'pricefeed':
            contract = priceFeed;
            break;
        case 'quickswap':
            contract = quickSwap;
            break;
        case 'tablecoin':
            contract = tablecoin;
            break;
        case 'treasury':
            contract = treasury;
            break;
        default:
            contract = ZERO_ADDRESS;
    }

    const storage = JSON.parse(localStorage.getItem("contractAddress")) ?? {};
    let contractAddress = {...storage};
    if (!contractAddress[contractName]) {
        contractAddress[contractName] = ZERO_ADDRESS;
    }
    try {
        contractAddress[contractName] = contract.address;
        localStorage.setItem('contractAddress', JSON.stringify(contractAddress));

    } catch (e) {
        console.log(e);
    }
    return contractAddress[contractName];
}

export {contractAddress};