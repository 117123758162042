import styles from "../styles/sidebar.module.scss";
import {Divider, List, ListItem} from "@chakra-ui/react";
import {treasury} from "../contractABI/treasury";
import {Link} from "react-router-dom";
import {ReactComponent as RoundTableLogo} from '../images/round-table-logo.svg';
import {ReactComponent as TwitterLogo} from '../images/twitter.svg';
import {ReactComponent as LinkedinLogo} from '../images/linkedin.svg';

import {
    DashboardIcon,
    DepositIcon,
    DocsIcon,
    GovernanceIcon,
    WithdrawIcon,
} from "./icons";
import {useEffect, useState} from "react";

const Sidebar = ({active}) => {
    const [paused, setPaused] = useState(true);

    const isPaused = async () => {
        return await treasury?.paused();
    };

    useEffect(() => {
        isPaused().then((r) => setPaused(r));
    }, []);

    // CATCH EVENTS
    treasury.on("Paused", (r) => {
        setPaused(true);
        console.log("CATCH EVENT: Paused by " + r);
    });

    treasury.on("Unpaused", (r) => {
        setPaused(false);
        console.log("CATCH EVENT: Unpaused by " + r);
    });

    return (
        <section className={styles.panel}>
            <RoundTableLogo
                alt="Round Table"
                className={styles.logo}
            />
            <List className={styles.menu}>
                <ListItem className={active === "dashboard" ? styles.active : ""}>
                    <DashboardIcon/>
                    <Link to="/">Dashboard</Link>
                </ListItem>
                <ListItem className={active === "deposit" ? styles.active : ""}>
                    <DepositIcon/>
                    <Link to="/treasury/deposit">Deposit</Link>
                </ListItem>
                <ListItem className={active === "withdraw" ? styles.active : ""}>
                    <WithdrawIcon/>
                    <Link to="/treasury/withdraw">Withdraw</Link>
                </ListItem>
            </List>
            <Divider className={styles.divider}/>
            <List className={`${styles.menu} ${styles.menu_other}`}>
                <ListItem className={styles.disable}>
                    <GovernanceIcon/>
                    Governance
                </ListItem>
                <ListItem className={styles.disable}>
                    <DocsIcon/>
                    Docs
                </ListItem>
            </List>
            <List className={styles.social_menu}>
                <ListItem>
                    <a
                        href="https://www.linkedin.com/company/crypto-guys/?originalSubdomain=ch"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <LinkedinLogo alt="Linkedin"/>
                    </a>
                </ListItem>
                <ListItem>
                    <a href="#">
                        <TwitterLogo alt="Twitter"/>
                    </a>
                </ListItem>
            </List>
        </section>
    );
};

export default Sidebar;
