import { ethers } from "ethers";

let provider;
let priceFeed;
let signer;

const address = "0xf93a58438F840a837CEFC3776446d400F5144288";
const abi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "getAmountInUsd",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];

if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
} else {
  let provider = ethers.getDefaultProvider(
      "https://polygon-mainnet.g.alchemy.com/v2/net79hx7Sg8S-AnmkjNrcFzlC7G8-PMq"
  ); // Mumbai
  let signer = new ethers.VoidSigner(address, provider);
}

priceFeed = new ethers.Contract(address, abi, signer);

export { priceFeed, provider, signer };
