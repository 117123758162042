import {useParams} from "react-router-dom";
import {useAddress, useNetworkMismatch} from "@thirdweb-dev/react";
import {useEffect, useMemo, useState} from "react";
import "../styles/globals.scss";
import styles from "../styles/index.module.scss";
import trasuryInteractionStyle from "../styles/treasuryInteract.module.scss";
import Login from "../components/login";
import Sidebar from "../components/sidebar";
import User from "../components/user";
import {Heading, HStack, Input, VStack} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import debounce from 'lodash.debounce';
import {getTokenMeta, getTokenPrice} from "../modules/tokens";
import {depositInTreasury, getLiquidityToken, withdrawFromTreasury} from "../modules/treasury";
import {balanceOf, calculateExchange, getAllowance, setAllowance} from "../modules/utilities";
import {contractAddress} from "../modules/contract";
import {ReactComponent as ArrowUp} from '../images/arrow-up.svg';
import {ReactComponent as ArrowDown} from '../images/arrow-down.svg';
import {ReactComponent as Cups} from '../images/cups.svg';

const TreasuryInteract = () => {
    const {interact} = useParams();
    const address = useAddress();
    const isMismatched = useNetworkMismatch();

    const [mode, setMode] = useState(interact);

    const [depositAmount, setDepositAmount] = useState(0);
    const [depositAmountInUsd, setDepositAmountInUsd] = useState(0);
    const [withdrawAmount, setWithdrawAmount] = useState(0);
    const [withdrawAmountInUsd, setWithdrawAmountInUsd] = useState(0);
    const [depositCoin, setDepositCoin] = useState({});
    const [withdrawCoin, setWithdrawCoin] = useState({});
    const [depositToken, setDepositToken] = useState();
    const [withdrawToken, setWithdrawToken] = useState();

    const setTokenAddress = async () => {
        const withdrawTokenAddress = await contractAddress('tablecoin');
        const depositTokenAddress = await getLiquidityToken();
        setDepositToken(depositTokenAddress);
        setWithdrawToken(withdrawTokenAddress);
        return [depositTokenAddress, withdrawTokenAddress];
    }

    const setCoins = async (depositTokenAddress, withdrawTokenAddress) => {
        const treasuryAddress = await contractAddress('treasury');
        const depositTokenMeta = await getTokenMeta(depositTokenAddress);
        const depositTokenBalance = await balanceOf(depositTokenAddress, address);
        const depositTokenAllowance = await getAllowance(address, treasuryAddress, depositTokenAddress);
        const depositCoin = {
            type: "depositCoin",
            address: depositTokenAddress,
            name: depositTokenMeta['name'],
            symbol: depositTokenMeta['symbol'],
            decimals: depositTokenMeta['decimals'],
            balance: depositTokenBalance,
            allowance: depositTokenAllowance,
        }

        const withdrawTokenMeta = await getTokenMeta(withdrawTokenAddress);
        const withdrawTokenBalance = await balanceOf(withdrawTokenAddress, address);
        const withdrawTokenAllowance = await getAllowance(address, treasuryAddress, withdrawTokenAddress);
        const withdrawCoin = {
            type: "withdrawCoin",
            address: withdrawTokenAddress,
            name: withdrawTokenMeta['name'],
            symbol: withdrawTokenMeta['symbol'],
            decimals: withdrawTokenMeta['decimals'],
            balance: withdrawTokenBalance,
            allowance: withdrawTokenAllowance,
        }
        return [depositCoin, withdrawCoin];
    }

    useEffect(() => {
        setTokenAddress().then(
            ([depositTokenAddress, withdrawTokenAddress]) => setCoins(depositTokenAddress, withdrawTokenAddress).then(
                ([depositCoin, withdrawCoin]) => {
                    setDepositCoin(depositCoin);
                    setWithdrawCoin(withdrawCoin);
                }
            )
        );
    }, [address]);

    useEffect(() => {
        setMode(interact);
    }, [interact]);

    const calculateChange = (amount) => {
        // todo: check deposit cap

    }

    const deposit = async (token, amount) => {
        await depositInTreasury(token.address, amount).then(esito => {
            if (esito) {
                setCoins(depositToken, withdrawToken).then(
                    ([depositCoin, withdrawCoin]) => {
                        setDepositCoin(depositCoin);
                        setWithdrawCoin(withdrawCoin);
                    })
            } else {
                console.log("Deposit error");
            }
        });
    }

    const withdraw = async (token, amount) => {
        await withdrawFromTreasury(token.address, amount).then(esito => {
            if (esito) {
                setCoins(depositToken, withdrawToken).then(
                    ([depositCoin, withdrawCoin]) => {
                        setDepositCoin(depositCoin);
                        setWithdrawCoin(withdrawCoin);
                    })
            } else {
                console.log("Deposit error");
            }
        });
    }

    const updateAllowance = async (token, amount) => {
        await setAllowance(token.address, amount).then(esito => {
            if (esito) {
                const updateData = {
                    ...token,
                    allowance: esito,
                }
                if (token.type === 'depositCoin') {
                    setDepositCoin(updateData);
                } else {
                    setWithdrawCoin(updateData)
                }
            } else {
                console.log("Set allowance error");
            }
        });
    }

    const setMaxBalance = (mode) => {
        if (mode === 'deposit') {
            setDepositAmount(depositCoin.balance);
            setChange(depositCoin, withdrawCoin, depositCoin.balance, mode).then();
        } else {
            setWithdrawAmount(withdrawCoin.balance);
            setChange(withdrawCoin, depositCoin, withdrawCoin.balance, mode).then();
        }
    }

    const controlInput = (mode, amount) => {
        if (mode === 'deposit') {
            // setDepositAmount(amount.replace(/^0+/, ''));
            setDepositAmount(amount);
        } else {
            // setWithdrawAmount(amount.replace(/^0+/, ''));
            setWithdrawAmount(amount);
        }
    }

    const setChange = async (tokenA, tokenB, amount, mode) => {
        let priceInUsd = 0;
        if (amount) {
            priceInUsd = await getTokenPrice(tokenA.address);
            priceInUsd = priceInUsd * amount;
        }
        if (mode === "deposit") {
            setDepositAmountInUsd(priceInUsd);
            const tokenBAmount = await calculateExchange(tokenA.address, tokenB.address, amount, 'deposit');
            setWithdrawAmount(tokenBAmount);
            let changePrice = await getTokenPrice(tokenB.address);
            changePrice = changePrice * tokenBAmount;
            setWithdrawAmountInUsd(changePrice);
        } else {
            setWithdrawAmountInUsd(priceInUsd);
            const tokenAAmount = await calculateExchange(tokenB.address, tokenA.address, amount, 'withdraw');
            setDepositAmount(tokenAAmount);
            let changePrice = await getTokenPrice(tokenB.address);
            changePrice = changePrice * tokenAAmount;
            setDepositAmountInUsd(changePrice);
        }
    }

    const debouncedChangeHandler = useMemo(
        () => debounce(setChange, 500)
        , []);

    return (
        <div className="App">
            {address && !isMismatched ?
                <div className="exchange">
                    <HStack className={styles.panel}>
                        <Sidebar active={mode}/>
                        <section className={styles.page}>
                            <User address={address}/>
                            <section className={trasuryInteractionStyle.masterPanel}>
                                <VStack w="50%">
                                    <HStack w="100%" justifyContent="end">
                                        <Cups/>
                                    </HStack>
                                    <VStack className={trasuryInteractionStyle.innerPanel}>
                                        <Heading>{mode[0].toUpperCase() + mode.substring(1)}</Heading>
                                        <VStack className={trasuryInteractionStyle.depositPanel}>
                                            <section
                                                className={mode === "deposit" ? trasuryInteractionStyle.first : trasuryInteractionStyle.last}>
                                                {depositCoin ? (
                                                    <HStack className={trasuryInteractionStyle.row}>
                                                        <div className={trasuryInteractionStyle.box}>
                                                            <p className={trasuryInteractionStyle.small}>Allowance: {depositCoin.allowance}</p>
                                                            <Input
                                                                value={depositAmount}
                                                                type="number"
                                                                placeholder="0,0"
                                                                onChange={(e) => {
                                                                    controlInput(mode, e.target.value);
                                                                    debouncedChangeHandler(depositCoin, withdrawCoin, e.target.value, mode);
                                                                }}
                                                                disabled={mode === "withdraw"}
                                                            />
                                                            <p className={trasuryInteractionStyle.small}>${depositAmountInUsd}</p>
                                                        </div>
                                                        <div className={trasuryInteractionStyle.box}>
                                                            <p className={trasuryInteractionStyle.small}>Balance: {depositCoin.balance}</p>
                                                            <div className={trasuryInteractionStyle.tokenBox}>
                                                                <span onClick={() => setMaxBalance(mode)} style={{
                                                                    visibility: mode === "withdraw" ? 'hidden' : 'visible'
                                                                }} className={trasuryInteractionStyle.max}>MAX</span>
                                                                <p className={trasuryInteractionStyle.token}>
                                                                    {depositCoin.address ? (<img
                                                                        src={`${process.env.REACT_APP_BASENAME}token/${depositCoin.address}.png`}/>) : ""}
                                                                    {depositCoin.symbol}
                                                                </p>
                                                            </div>
                                                            <p className={trasuryInteractionStyle.small}>&nbsp;</p>
                                                        </div>
                                                    </HStack>
                                                ) : ""}
                                            </section>

                                            {mode === "deposit" ? (
                                                <Link className={trasuryInteractionStyle.arrow} to="/treasury/withdraw"
                                                      onClick={() => setMode('withdraw')}>
                                                    <ArrowDown/>
                                                </Link>
                                            ) : (
                                                <Link className={trasuryInteractionStyle.arrow} to="/treasury/deposit"
                                                      onClick={() => setMode('deposit')}>
                                                    <ArrowUp/>
                                                </Link>
                                            )}

                                            <section
                                                className={mode === "withdraw" ? trasuryInteractionStyle.first : trasuryInteractionStyle.last}>
                                                {withdrawCoin ? (
                                                    <HStack className={trasuryInteractionStyle.row}>
                                                        <div className={trasuryInteractionStyle.box}>
                                                            <p className={trasuryInteractionStyle.small}>Allowance: {withdrawCoin.allowance}</p>
                                                            <Input
                                                                value={withdrawAmount}
                                                                type="number"
                                                                placeholder="0,0"
                                                                onChange={(e) => {
                                                                    controlInput(mode, e.target.value);
                                                                    debouncedChangeHandler(withdrawCoin, depositCoin, e.target.value, mode);
                                                                }}
                                                                disabled={mode === "deposit"}
                                                            />
                                                            <p className={trasuryInteractionStyle.small}>${withdrawAmountInUsd}</p>
                                                        </div>
                                                        <div className={trasuryInteractionStyle.box}>
                                                            <p className={trasuryInteractionStyle.small}>Balance: {withdrawCoin.balance}</p>
                                                            <div className={trasuryInteractionStyle.tokenBox}>
                                                                <span onClick={() => setMaxBalance(mode)} style={{
                                                                    visibility: mode === "deposit" ? 'hidden' : 'visible'
                                                                }} className={trasuryInteractionStyle.max}>MAX</span>
                                                                <p className={trasuryInteractionStyle.token}>
                                                                    {withdrawCoin.address ? (<img
                                                                        src={`${process.env.REACT_APP_BASENAME}token/${withdrawCoin.address}.png`}/>) : ""}
                                                                    {withdrawCoin.symbol}
                                                                </p>
                                                            </div>
                                                            <p className={trasuryInteractionStyle.small}>&nbsp;</p>
                                                        </div>
                                                    </HStack>
                                                ) : ""}
                                            </section>
                                        </VStack>
                                        <HStack>
                                            {mode === "deposit" ?
                                                (depositAmount <= 0) ? (
                                                        <button disabled className={trasuryInteractionStyle.btn}>Enter an
                                                            amount</button>) :
                                                    (
                                                        depositCoin['balance'] < depositAmount ? (
                                                                <button disabled={true}
                                                                        className={trasuryInteractionStyle.btn}>Not
                                                                    sufficient found</button>) :
                                                            (
                                                                depositCoin['allowance'] >= depositAmount ? (
                                                                        <button
                                                                            onClick={() => deposit(depositCoin, depositAmount)}
                                                                            className={trasuryInteractionStyle.btn}>Deposit</button>) :
                                                                    (
                                                                        <button
                                                                            onClick={() => updateAllowance(depositCoin, depositAmount)}
                                                                            className={trasuryInteractionStyle.btn}>Approve
                                                                            use of {depositCoin.symbol}</button>
                                                                    )
                                                            )
                                                    ) :
                                                ""}

                                            {mode === "withdraw" ?
                                                (withdrawAmount <= 0) ? (
                                                        <button disabled className={trasuryInteractionStyle.btn}>Enter an
                                                            amount</button>) :
                                                    (
                                                        withdrawCoin['balance'] < withdrawAmount ? (
                                                                <button disabled={true}
                                                                        className={trasuryInteractionStyle.btn}>Not
                                                                    sufficient found</button>) :
                                                            (
                                                                withdrawCoin['allowance'] >= withdrawAmount ? (
                                                                        <button
                                                                            onClick={() => withdraw(withdrawCoin, withdrawAmount)}
                                                                            className={trasuryInteractionStyle.btn}>Withdraw</button>) :
                                                                    (
                                                                        <button
                                                                            onClick={() => updateAllowance(withdrawCoin, withdrawAmount)}
                                                                            className={trasuryInteractionStyle.btn}>Approve
                                                                            use of {withdrawCoin.symbol}</button>
                                                                    )
                                                            )
                                                    ) :
                                                ""}
                                        </HStack>
                                    </VStack>
                                </VStack>
                            </section>
                        </section>
                    </HStack>
                </div> :
                <Login/>}
        </div>
    );
}

export default TreasuryInteract;