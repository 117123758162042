import styles from "../styles/portfolio.module.scss";
import {Box, Heading, VStack, HStack, Link} from "@chakra-ui/react";
import {ReactComponent as ArrowRight} from '../images/arrow.svg';
import {useEffect, useState} from "react";
import {getTotalLiabilitiesLive} from "../modules/treasury";

const Portfolio = ({token, tokenMeta, totalReservesLive, type}) => {
    // console.log(token);
    // console.log(tokenMeta);
    // console.log(totalReservesLive);

    const [liabilities, setLiabilities] = useState(0);

    useEffect(() => {
        setLiabilities(getTotalLiabilitiesLive(tokenMeta));
    }, [tokenMeta]);

    return (
        <section className={styles.panel}>
            <VStack className={styles.dashboard}>
                <Heading>{type[0].toUpperCase() + type.substring(1)}</Heading>
                <section className={styles.table}>
                    <HStack className={`${styles.row} ${styles.first}`}>
                        <Box className={`${styles.cell} ${styles.first}`}>&nbsp;</Box>
                        <Box className={styles.cell}>Unity Value</Box>
                        <Box className={styles.cell}>Quantity</Box>
                        <Box className={styles.cell}>Total Value</Box>
                        <Box className={styles.cell}>Weight</Box>
                        <Box className={`${styles.cell} ${styles.last}`}>PolygonScan</Box>
                    </HStack>
                    {token && token.length
                        ? token.map((value, i) =>
                            (type === "assets" || tokenMeta[value].aaveDebtBalance ? (
                                    <HStack key={i} className={styles.row}>
                                        <Box className={`${styles.cell} ${styles.first}`}>
                                            {tokenMeta[value].name} ({tokenMeta[value].symbol})
                                        </Box>
                                        <Box className={styles.cell}>
                                            {tokenMeta[value].price ? tokenMeta[value].price.toFixed(2) : ''}
                                        </Box>
                                        <Box className={styles.cell}>
                                            {type === "assets"
                                                ? tokenMeta[value].treasuryBalance
                                                : tokenMeta[value].aaveDebtBalance}
                                        </Box>
                                        <Box className={styles.cell}>
                                            $
                                            {type === "assets"
                                                ? (
                                                    tokenMeta[value].price *
                                                    tokenMeta[value].treasuryBalance
                                                ).toFixed(2)
                                                : (
                                                    tokenMeta[value].price *
                                                    tokenMeta[value].aaveDebtBalance
                                                ).toFixed(2)}
                                        </Box>
                                        <Box className={styles.cell}>
                                            {type === "assets" ? (
                                                ((tokenMeta[value].price *
                                                        tokenMeta[value].treasuryBalance) /
                                                    totalReservesLive) *
                                                100
                                            ).toFixed(2) : ((tokenMeta[value].aaveDebtBalance / liabilities) * 100).toFixed(2)}%
                                        </Box>
                                        <Box className={`${styles.cell} ${styles.last}`}>
                                            <Link
                                                href={`https://polygonscan.com/address/${value}`}
                                                isExternal
                                            >
                                                <ArrowRight alt="Go to Polygonscan"/>
                                            </Link>
                                        </Box>
                                    </HStack>) : ""
                            )
                        )
                        : ""}
                </section>
            </VStack>
        </section>
    );
};

export default Portfolio;
