import {treasury} from "../contractABI/treasury";
import {getDebtToken, getPermittedToken, getTokenMeta, getTokenPrice} from "./tokens";
import {aave} from "../contractABI/aave";
import {balanceOf, ZERO_ADDRESS} from "./utilities";
import {erc20Mapping} from "../contractABI/erc20Mapping";

const getTokenTreasuryBalance = async (tokenAddress) => {
    const storage = JSON.parse(sessionStorage.getItem("tokensBalance")) ?? {};
    let tokenBalance = {...storage};
    const tokenMeta = await getTokenMeta(tokenAddress);
    try {
        const balance = await treasury.getWholeBalance(tokenAddress);
        tokenBalance[tokenAddress] = balance / 10 ** tokenMeta['decimals'];
        sessionStorage.setItem('tokensBalance', JSON.stringify(tokenBalance));
    } catch (e) {
        console.log(e);
    }
    return tokenBalance ? tokenBalance[tokenAddress] : 0;
};

const getTokenTreasuryWeight = async (tokenAddress) => {
    const storage = JSON.parse(sessionStorage.getItem("tokenWeigth")) ?? {};
    let tokenWeigth = {...storage};
    try {
        const weigth = await treasury.getTokenWeight(tokenAddress);
        tokenWeigth[tokenAddress] = weigth.toNumber() / 100;
        sessionStorage.setItem('tokenWeigth', JSON.stringify(tokenWeigth));
    } catch (e) {
        console.log(e);
    }
    return tokenWeigth[tokenAddress] ? tokenWeigth : 0;
};

const getTokenDebtLive = async (tokenAddress) => {
    const stbDebtToken = await getDebtToken(tokenAddress, 'stable');
    const varDebtToken = await getDebtToken(tokenAddress, 'variable');
    let stbDebtBalance = 0;
    let variableDebtBalance = 0;
    const tokenMeta = await getTokenMeta(tokenAddress);

    if (
        stbDebtToken !== ZERO_ADDRESS &&
        varDebtToken !== ZERO_ADDRESS
    ) {
        stbDebtBalance = await balanceOf(stbDebtToken, aave.address);
        variableDebtBalance = await balanceOf(varDebtToken, aave.address);
    }

    return 0 + (stbDebtBalance + variableDebtBalance);

};

const getTotalLiabilitiesLive = (tokenMeta) => {
    let liabilities = 0;
    if (JSON.stringify(tokenMeta) !== '{}') {
        Object.keys(tokenMeta).forEach(e => {
            liabilities += tokenMeta[e]['aaveDebtBalance'];
        });
    }
    return liabilities;
}

const getTotalReservesLive = async () => {
    const tokenList = await getPermittedToken(true);
    let reserveLive = 0;

    for (let i = 0; i < tokenList.length; i++) {
        const balance = await getTokenTreasuryBalance(tokenList[i]);
        const price = await getTokenPrice(tokenList[i]);
        reserveLive += price * balance;
    }
    return reserveLive;
}

const getLiquidityToken = async () => {
    let liquidityToken = JSON.parse(localStorage.getItem("liquidityToken"));
    try {
        liquidityToken = await erc20Mapping.liquidityToken();
        localStorage.setItem('liquidityToken', JSON.stringify(liquidityToken));
    } catch (e) {
        console.log(e);
    }
    return liquidityToken;
}

const getDepositCap = async () => {
    let depositCap = JSON.parse(localStorage.getItem("depositCap"));
    try {
        depositCap = await treasury.depositCap();
        localStorage.setItem('depositCap', JSON.stringify(depositCap));
    } catch (e) {
        console.log(e);
    }
    return depositCap;
}

const getIntrinsicValue = async () => {
    let intrinsicValue = JSON.parse(localStorage.getItem("intrinsicValue"));
    try {
        intrinsicValue = await treasury.getIntrinsicValue();
        localStorage.setItem('intrinsicValue', JSON.stringify(intrinsicValue));
    } catch (e) {
        console.log(e);
    }
    return intrinsicValue;
}

const getDepositFee = async () => {
    let depositFee = JSON.parse(localStorage.getItem("depositFee"));
    try {
        depositFee = await treasury.depositFee();
        localStorage.setItem('depositFee', JSON.stringify(depositFee));
    } catch (e) {
        console.log(e);
    }
    return depositFee;
}

const getWithdrawalFee = async () => {
    let withdrawalFee = JSON.parse(localStorage.getItem("withdrawalFee"));
    try {
        withdrawalFee = await treasury.withdrawalFee();
        localStorage.setItem('withdrawalFee', JSON.stringify(withdrawalFee));
    } catch (e) {
        console.log(e);
    }
    return withdrawalFee;
}


const depositInTreasury = async (tokenAddress, amount) => {
    try {
        const tokenMeta = await getTokenMeta(tokenAddress);
        let tx = await treasury.deposit(amount * (10 ** tokenMeta['decimals']));
        await tx.wait();
    } catch (e) {
        console.log(e);
        return false;
    }
    return true;
}

const withdrawFromTreasury = async (tokenAddress, amount) => {
    try {
        const tokenMeta = await getTokenMeta(tokenAddress);
        let tx = await treasury.withdraw(amount * (10 ** tokenMeta['decimals']));
        await tx.wait();
    } catch (e) {
        console.log(e);
        return false;
    }
    return true;
}

export {
    getTokenTreasuryBalance,
    getTokenTreasuryWeight,
    getTotalReservesLive,
    getTotalLiabilitiesLive,
    getTokenDebtLive,
    getLiquidityToken,
    getIntrinsicValue,
    getDepositCap,
    getDepositFee,
    getWithdrawalFee,
    depositInTreasury,
    withdrawFromTreasury
};